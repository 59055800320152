import React from 'react';
import cn from 'classnames';
import MUITableBody, { TableBodyProps } from '@material-ui/core/TableBody';
import useStyles from './useStyles';

interface Props extends TableBodyProps {
  interactive?: boolean;
  expanded?: boolean;
}

const TableBody = ({ className, children, interactive = false, expanded = false, ...rest }: Props) => {
  const classes = useStyles();

  const classNames = cn(className, classes.root, {
    [classes.interactive]: interactive,
    [classes.expanded]: expanded,
  });

  const overrideClasses = {
    root: classes.root,
  };

  return (
    <MUITableBody className={classNames} classes={overrideClasses} {...rest}>
      {children}
    </MUITableBody>
  );
};

export default TableBody;
