import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {},

  interactive: {
    cursor: 'pointer',
  },

  expanded: {
    '& td': {
      borderBottom: 'none',
    },
  },
}));
